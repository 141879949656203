/* Load Custom Fonts */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,300;0,500;0,700;1,100;1,300;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,500;1,700;1,900&display=swap');

/* Load Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base Styles */
@layer base {
    html { @apply h-full w-full; }
    body {
        @apply relative  leading-none  font-sans;
        @apply text-xs   sm:text-base  print:text-xs;
    }
    
    h1, h2, h3, h4, h5, h6 { @apply    font-heading pt-1; }
    h1 { @apply font-light   text-6xl  sm:text-8xl  print:text-6xl; }
    h2 { @apply font-medium  text-4xl  sm:text-6xl  print:text-4xl; }
    h3 { @apply font-bold    text-2xl  sm:text-4xl  print:text-2xl; }
    h4 { @apply font-medium  text-base sm:text-xl   print:text-base; }
}

@layer components {
    /* NavLink styling */
    a.active, a.active-parent, a.active-parent .link { @apply cursor-default; }

    /* Fix daisyUI table formatting */
    .table > thead > tr > th:first-child {
        @apply static;
    }

    .table-zebra tbody tr:nth-child(even) {
        @apply bg-base-200/50;
    }
    .table-zebra tbody tr.hover\:bg-base-200:nth-child(even):hover {
        @apply bg-base-200;
    }
    .table-zebra tbody tr.hover\:bg-error:nth-child(even):hover {
        @apply bg-error;
    }

    /* Remove input number up/down arrows */
    input[type=number].hide-arrows {
        -moz-appearance: textfield;
    }
    input[type='number'].hide-arrows::-webkit-inner-spin-button,
    input[type='number'].hide-arrows::-webkit-outer-spin-button {
        -webkit-appearance: none;
        @apply m-0;
    }

    /* Hide arrow in disabled dropdown */
    .select:disabled {
        background-image: none;
    }

    /* Class to ignore background effects */
    .bg-ignore {
        @apply hover:bg-transparent active:bg-transparent focus:bg-transparent;
    }

    /* General button styling */
    .btn { @apply uppercase; }
}
